@import '~@standardnotes/stylekit/dist/stylekit.css';

:root {
  --sn-stylekit-font-size-editor: 16px;
  --sn-stylekit-monospace-font: SFMono-Regular, Consolas, Liberation Mono, Menlo,
    'Ubuntu Mono', courier, monospace;
}

@media screen and (max-width: 650px) {
  :root {
    --sn-stylekit-font-size-editor: 18px;
  }
  h1 {
    font-size: 1.75em; // is usually 2em
  }
}

body,
html {
  background-color: transparent;
  background-color: var(--sn-stylekit-background-color);
  font-family: var(--sn-stylekit-sans-serif-font);
  font-size: var(--sn-stylekit-base-font-size);
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

* {
  // To prevent gray flash when focusing input on mobile Safari
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.sn-component {
  display: flex;
  flex-direction: column;
  font-size: var(--sn-stylekit-font-size-editor);
  min-height: 100vh;
  @media screen and (max-width: 420px) {
    min-height: -webkit-fill-available;
  }

  .sk-input.textarea {
    padding: 9px 10px;
    max-width: inherit;
    width: calc(100% - 22px);
    resize: vertical;
    overflow-y: auto;
    font-family: var(--sn-stylekit-sans-serif-font);
    font-size: var(--sn-stylekit-font-size-editor);
    line-height: 1.7;
  }
}

a {
  color: var(--sn-stylekit-info-color); // #086dd6;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

code {
  box-sizing: border-box;
  word-wrap: break-word;
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: var(--sn-stylekit-contrast-background-color);
  border-radius: 3px;
}

p {
  padding: 0px 1em;
}

.MuiTabs-root {
  background-color: var(--sn-stylekit-background-color);

  button {
    color: var(--sn-stylekit-text-color);
  }
}

@import './print.scss';
@import './dark.scss';
